export const TYPE_CHARGE = 1
export const TYPE_REFUND = 2
export const TYPE_TOKENIZATION = 3
export const TYPE_CHARGE_RECURRING = 4
export const TYPES = {
    "1": "Charge",
    "2": "Refund",
    "3": "Tokenise Card",
    "4": "Recurring Charge"
}
export const FILTER_CONFIG = [
    {
        "type": "String",
        "name": "uuid",
        "label": "UUID"
    },
    {
        "type": "Number",
        "name": "type",
        "label": "Type",
        "options": [
            {
                "text": "Charge",
                "value": 1
            },
            {
                "text": "Recurring Charge",
                "value": 4
            },
            {
                "text": "Tokenise Card",
                "value": 3
            }
        ]
    },
    {
        "type": "Number",
        "name": "status",
        "label": "Status",
        "options": [
            {
                "text": "Processing",
                "value": 1
            },
            {
                "text": "Successful",
                "value": 2
            },
            {
                "text": "Failed",
                "value": 3
            }
        ]
    },
    {
        "type": "String",
        "name": "request_data",
        "label": "Request"
    },
    {
        "type": "String",
        "name": "response_data",
        "label": "Response"
    },
    {
        "type": "String",
        "name": "metadata",
        "label": "Metadata"
    },
    {
        "type": "Date",
        "name": "created_at",
        "label": "Created"
    }
]
